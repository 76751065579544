<template>
  <div class="related-videos">
    <div class="related-videos-top-bg"></div>
    <div class="related-videos-bottom-bg"></div>
    <div class="related-videos-title">RELATED LESSONS</div>
    <div class="related-videos-subtitle">関連講座</div>
    <div class="swiper-button-prev">
      <icon
        name="swiper-button-prev-btn"
        class="swiper-button-prev-btn-icon"
      ></icon>
    </div>
    <div class="swiper-button-next">
      <icon
        name="swiper-button-next-btn"
        class="swiper-button-next-btn-icon"
      ></icon>
    </div>
    <div class="swiper-index">
      <div class="swiper-index-page-text">Page&nbsp;</div>
      {{ viewState.realIndex }}/{{ viewState.videosCount }}
    </div>
    <swiper
      class="swiper-container"
      :loop="viewState.videosCount > 1"
      :slidesPerView="4"
      :slidesPerGroup="4"
      :breakpoints="breakpoints"
      :navigation="{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }"
      @activeIndexChange="activeIndexChange"
    >
      <swiper-slide
        v-for="(x, index) in viewState.videosCount"
        :key="index"
        :class="'related-videos-items'"
      >
        <relatedVideosItem
          :imgIndex="index % 4"
          :class="(index + 1) % 2 == 0 ? 'related-videos-item-mt110' : ''"
          :video="videos[index]"
          :levels="levels"
        ></relatedVideosItem>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import relatedVideosItem from "./RelatedVideosItem.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import { defineComponent, reactive } from "vue";
SwiperCore.use([Navigation]);

export default defineComponent({
  props: {
    videos: {
      default: [],
    },
    levels: {
      default: [],
    },
  },
  components: {
    relatedVideosItem,
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const viewState = reactive({
      videosCount: props.videos.length ? props.videos.length : 1,
      realIndex: 1,
    });

    const breakpoints = {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      766: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
      },
      1036: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 20,
      },
      1366: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 20,
      },
    };

    // method
    const activeIndexChange = ({ realIndex }) => {
      viewState.realIndex = realIndex + 1;
    };

    return {
      viewState,
      breakpoints,

      // method
      activeIndexChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.related-videos {
  max-width: 1266px;
  height: 882px;
  margin-left: 50px;
  margin-top: 80px;
  margin-bottom: 50px;
  margin-right: 50px;
  background: rgba(253, 255, 255, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include mq(sm) {
    margin-left: 20px;
    margin-top: 60px;
    margin-bottom: 20px;
    margin-right: 20px;
    height: auto;
    padding-bottom: 60px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background: $white;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    z-index: -1;
  }
  .related-videos-top-bg {
    position: absolute;
    width: 584px;
    height: 584px;
    background: $primary;
    opacity: 0.8;
    border-radius: 50%;
    right: -292px;
    top: -292px;
    z-index: -1;
    @include mq(sm) {
      width: 255px;
      height: 255px;
      right: -147px;
      top: -128px;
    }
  }
  .related-videos-bottom-bg {
    position: absolute;
    width: 584px;
    height: 584px;
    background: $secondary;
    opacity: 0.4;
    border-radius: 50%;
    bottom: -342px;
    left: -292px;
    z-index: -1;
    @include mq(sm) {
      width: 255px;
      height: 255px;
      bottom: -117px;
      left: -128px;
    }
  }
  .swiper-button-prev {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 125px;
    top: 147px;
    .swiper-button-prev-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      right: unset;
      top: 30px;
      left: 30px;
      .swiper-button-prev-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-button-next {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 65px;
    top: 147px;
    .swiper-button-next-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      right: unset;
      top: 0;
      left: 70px;
      .swiper-button-next-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-index {
    display: none;
    @include mq(sm) {
      display: flex;
      width: 88px;
      height: 15px;
      align-items: baseline;
      position: relative;
      top: -24px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black-50;
      justify-content: flex-end;
      padding-right: 30px;
    }
  }
  .related-videos-title {
    margin-left: 65px;
    margin-top: 45px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: $black;
    height: 100px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    &::before {
      position: absolute;
      content: "";
      width: 40px;
      height: 0px;
      left: 0;
      bottom: 16px;
      border-bottom: 4px solid $black;
    }
    @include mq(sm) {
      margin-left: 30px;
      margin-top: 60px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $black;
      height: unset;
      &::before {
        left: 0;
        bottom: -12px;
      }
    }
  }
  .related-videos-subtitle {
    margin-left: 65px;
    margin-top: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    color: $black;
    opacity: 0.5;
    user-select: none;
    -webkit-user-select: none;
    @include mq(sm) {
      margin-left: 30px;
      margin-top: 30px;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black;
    }
  }
  .swiper-container {
    margin-left: 65px;
    margin-right: 65px;
    width: calc(100% - 130px);
    .related-videos-items {
      height: 715px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .related-videos-item-mt110 {
        margin-top: 110px;
      }
    }
    @include mq(sm) {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      width: 275px;
      .related-videos-items {
        height: auto;
        .related-videos-item-mt110 {
          margin-top: 0px;
        }
      }
    }
  }
}
</style>
