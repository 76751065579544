<template>
  <div class="key-words">
    <div class="key-words-title-area">
      <commonTitle :title="'KEYWORDS'" :subTitle="'キーワード'"></commonTitle>
    </div>
    <div class="key-words-btns">
      <el-popover
        placement="bottom"
        trigger="click"
        width="320px"
        v-for="(item, index) in keyWordList"
        :key="index"
        :offset="16"
        popper-class="key-words-popper"
        :ref="componentRefs[index]"
        :hide-after="0"
        :transition="'none'"
      >
        <div class="key-words-popper-value">
          <icon
            name="key-words-close-btn"
            @click="popperCloseClick(index)"
          ></icon>
          {{ item.detail }}
        </div>
        <template #reference>
          <div
            class="key-words-btn"
            :class="viewState.isActiveKey === index ? 'isActive' : ''"
            @click="keyWordsBtnClick(index)"
          >
            {{ item.keyword }}
          </div>
        </template>
      </el-popover>
    </div>
    <div
      v-if="keyList.length > 10"
      class="key-words-more"
      @click="keyWordsMoreClick"
    >
      <template v-if="keyWordList.length < keyList.length">
        <div class="key-words-more-text">もっと見る</div>
        <icon name="sp-key-word-more-btn"></icon>
      </template>
      <template v-else>
        <div class="key-words-more-text">閉じる</div>
        <icon name="sp-key-word-less-btn"></icon>
      </template>
    </div>
  </div>
</template>

<script>
import { ElPopover } from "element-plus";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import { useStore } from "vuex";
import { defineComponent, reactive, ref, computed } from "vue";
export default defineComponent({
  props: {
    keyList: {
      default: [],
    },
  },
  setup(props) {
    const store = useStore();
    const isSp = store.state.isSp;
    const viewState = reactive({
      isActiveKey: null,
      isShow: false,
      moreKeyWord: false,
    });

    document.onclick = () => {
      viewState.isActiveKey = "";
    };

    const keyWordList = computed(() => {
      if (isSp) {
        return props.keyList.slice(
          0,
          viewState.moreKeyWord ? props.keyList.length : 10
        );
      } else {
        return props.keyList;
      }
    });

    const componentRefs = [];
    keyWordList.value.forEach((item, index) => {
      componentRefs[index] = ref();
    });

    const keyWordsMoreClick = () => {
      if (keyWordList.value.length < props.keyList.length) {
        viewState.moreKeyWord = true;
      } else {
        viewState.moreKeyWord = false;
      }
    };
    const keyWordsBtnClick = (key) => {
      if (viewState.isActiveKey === key) {
        viewState.isActiveKey = "";
        return;
      }
      viewState.isActiveKey = key;
    };
    const popperCloseClick = (index) => {
      viewState.isActiveKey = "";
      componentRefs[index].value.hide();
    };

    return {
      props,
      isSp,
      keyWordList,
      componentRefs,
      keyWordsMoreClick,
      keyWordsBtnClick,
      popperCloseClick,
      viewState,
    };
  },
  components: {
    commonTitle,
    ElPopover,
  },
});
</script>

<style lang="scss" scoped>
.key-words {
  max-width: 1201px;
  .key-words-title-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .key-words-detail {
      margin-left: 65px;
      min-width: 456px;
      width: 456px;
      height: 90px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $dark-grey;
      text-align: left;
    }
    @include mq(sm) {
      flex-direction: column;
      .key-words-detail {
        min-width: unset;
        margin-left: 30px;
        margin-top: 30px;
        margin-right: 30px;
        width: 275px;
        height: 150px;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
    }
  }
  .key-words-btns {
    max-width: 1136px;
    margin-top: 40px;
    margin-left: 65px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;
    .key-words-btn {
      margin-right: 19px;
      margin-bottom: 20px;
      height: 60px;
      width: auto;
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.1em;
      color: $black;
      border: 1px solid $black;
      cursor: pointer;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 0 29px;
      @media (any-hover: hover) {
        &:hover {
          background: $primary;
          font-weight: 700;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.1em;
          color: $white;
          border: 1px solid $primary;
        }
      }
    }
    .isActive {
      background: $primary;
      color: $white;
      box-shadow: 0px 20px 30px rgba(54, 130, 243, 0.25);
      border: 1px solid $primary;
    }
    @include mq(sm) {
      margin: 30px 30px 25px;
      .key-words-btn {
        height: 40px;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.1em;
        color: $black;
        padding: 14px 18px;
        border: 1px solid $black;
        border-radius: 8px;
        margin-right: 15px;
        margin-bottom: 15px;
        @media (any-hover: hover) {
          &:hover {
            background: $primary;
            font-size: 12px;
            color: $white;
            border: 1px solid $primary;
          }
        }
      }
      .isActive {
        box-shadow: 0px 0px 30px rgba(54, 131, 245, 0.25);
        border-radius: 8px;
        color: $white;
        border: 1px solid $primary;
      }
    }
  }
  .key-words-more {
    display: none;
    @include mq(sm) {
      height: 60px;
      background: $black;
      box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .key-words-more-text {
        margin-top: 21;
        margin-left: 50px;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $white;
      }
      svg {
        margin-right: 30px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
