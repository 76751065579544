<template>
  <div class="related-videos-item">
    <img :src="video?.image_url" alt="" class="related-videos-item-img" @click="seeVideo"/>
    <div v-if="isLogin" class="related-videos-item-mask">
      <icon
        v-if="viewState.isFavorite"
        @click="deleteFavorite()"
        name="bookmark-narrow-active"
        class="
          bookmark-narrow
          bookmark-narrow-active
        "
      ></icon>
      <icon
        v-else
        @click="addFavorite()"
        name="bookmark-narrow"
        class="bookmark-narrow"
      ></icon>
    </div>
    <div class="related-videos-item-title">
      <div>
        {{ video.name }}
      </div>
    </div>

    <div class="related-videos-item-detail">
      <div class="related-videos-item-detail-item">
        <div class="related-videos-item-detail-item-top">レベル</div>
        <div class="related-videos-item-detail-item-bottom">{{ levels ? levels[video.level_id].name : video.level_name }}</div>
      </div>
      <div class="related-videos-item-detail-item">
        <div class="related-videos-item-detail-item-top">チャプター</div>
        <div class="related-videos-item-detail-item-bottom">{{ video.chapters_count }}</div>
      </div>
      <div class="related-videos-item-detail-item">
        <div class="related-videos-item-detail-item-top">視聴時間</div>
        <div class="related-videos-item-detail-item-bottom">{{ totalAttendanceTimeHm }}</div>
      </div>
    </div>
    <div
      class="related-videos-item-btn related-videos-item-btn-animation"
      @click="seeVideo"
    >
      <div class="related-videos-item-btn-text">講座詳細を見る</div>
      <icon
        name="chapter-detail-btn"
        class="related-videos-item-btn-icon"
      ></icon>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http";
import display from "@/utils/display";
import { defineComponent, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: ['imgIndex', 'video', 'levels'],
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const viewState = reactive({
      isFavorite: props['video'].is_favorite
    });

    const isLogin = computed(() => store.state.user.isLogin);
    const totalAttendanceTimeHm = computed(() => display.timestampToHm(props['video'].total_attendance_time));

    // method
    const seeVideo = () => {
      router.push(`/lecture-detail/${props['video'].code}`)
    }
    const addFavorite = () => {
      // 先にお気に入り状態にしておく
      viewState.isFavorite = true;

      http
        .post(`/api/lectures/${props['video'].key}/favorites`)
        .then((response) => {
          viewState.isFavorite = true;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          viewState.isFavorite = false;
          return error.response;
        });
    }
    const deleteFavorite = () => {
      // 先にお気に入り解除状態にしておく
      viewState.isFavorite = false;
      
      http
        .delete(`/api/lectures/${props['video'].key}/favorites`)
        .then((response) => {
          viewState.isFavorite = false;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          viewState.isFavorite = true;
          return error.response;
        });
    }

    return {
      viewState,
      isLogin,
      totalAttendanceTimeHm,

      // method
      seeVideo,
      addFavorite,
      deleteFavorite,
    };
  },
});
</script>

<style lang="scss" scoped>
.related-videos-item {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:hover {
    .related-videos-item-img {
      border: 0.5px solid rgba(0, 0, 0, 0.5);
    }
  }
  @include mq(sm) {
    margin-top: 0px;
  }
  .related-videos-item-img {
    cursor: pointer;
    width: 269px;
    height: 300px;
    border: 0.5px solid #fdffff;
    @include mq(sm) {
      border: 0.5px solid rgba(0, 0, 0, 0.5);
      width: 275px;
      height: 180px;
      object-fit: cover;
    }
  }
  .related-videos-item-mask {
    position: absolute;
    width: 269px;
    height: 60px;
    background: rgba(15, 15, 17, 0.1);
    top: 240px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-left: 0.5px solid #fdffff;
    border-right: 0.5px solid #fdffff;
    @include mq(sm) {
      top: 120px;
      width: 275px;
      height: 60px;
      border-left: 0.5px solid rgba(0, 0, 0, 0);
      border-right: 0.5px solid rgba(0, 0, 0, 0);
    }
    .bookmark-narrow {
      cursor: pointer;
      margin-right: 20px;
      width: 19px;
      height: 27px;
      opacity: 1;
      @include mq(sm) {
        margin-right: 28px;
      }
    }
    .bookmark-narrow-active {
      cursor: pointer;
      box-shadow: inset 0px 20px 12px rgba(54, 131, 245, 0.25),
        0px 20px 30px rgba(54, 131, 245, 0.25);
    }
    &::after {
      content: '';
      position: absolute;
      right: 53px;
      top: 20px;
      width: 0px;
      height: 20px;
      border-right: 0.5px solid $white;
      @include mq(sm) {
        right: 73px;
      }
    }
  }
  .related-videos-item-title {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    width: 269px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(sm) {
      width: 275px;
    }
    div {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 229px;
      height: 60px;
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mq(sm) {
        width: 275px;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
      }
    }
  }
  .related-videos-item-detail {
    opacity: 0;
    height: 0px;
    display: flex;
    flex-direction: row;
    width: 269px;
    justify-content: space-between;
    align-items: center;
    @include mq(sm) {
      opacity: 1;
      height: 78px;
      width: 275px;
    }
    .related-videos-item-detail-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .related-videos-item-detail-item-top {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: rgba(89, 88, 87, 0.8);
        @include mq(sm) {
          font-weight: 500;
          font-size: 10px;
          line-height: 100%;
          color: $black;
        }
      }
      .related-videos-item-detail-item-bottom {
        height: 28px;
        margin-top: 3px;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: $black;
        @include mq(sm) {
          margin-top: 10px;
          height: 18px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.09em;
          color: $black;
        }
      }
      &:nth-child(1) {
        width: 78.63px;
        @include mq(sm) {
          width: 96px;
        }
      }
      &:nth-child(2) {
        width: 99px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 0px;
          width: 0px;
          height: 20px;
          border-left: 0.5px solid $black-50;
        }
        &::after {
          content: '';
          position: absolute;
          right: 0px;
          width: 0px;
          height: 20px;
          border-right: 0.5px solid $black-50;
        }
        @include mq(sm) {
          width: 99px;
          &::before,
          &::after {
            height: 15px;
          }
        }
      }
      &:nth-child(3) {
        width: 91.37px;
        @include mq(sm) {
          width: 89px;
        }
      }
    }
  }
  .related-videos-item-btn {
    cursor: pointer;
    opacity: 0;
    width: 269px;
    height: 0px;
    background: $black;
    display: flex;
    justify-content: space-between;
    min-width: 227px;
    align-items: center;
    background: $black;
    position: relative;
    z-index: 2;
    @include mq(sm) {
      opacity: 1;
      width: 275px;
      height: 60px;
    }
    .related-videos-item-btn-text {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0.08em;
      color: $white;
      margin-left: 30px;
      z-index: 2;
      @include mq(sm) {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $white;
        margin-left: 30px;
        // margin-right: 89px;
        white-space: nowrap;
      }
    }
    .related-videos-item-btn-icon {
      width: 40px;
      height: 10px;
      margin-right: 20px;
      z-index: 2;
    }
    @keyframes related-videos-item-btn-hover-keyframe {
      from {
        width: 0px;
      }
      to {
        width: 100%;
      }
    }
    &:hover {
      &::before {
        content: '';
        left: 0;
        height: 60px;
        position: absolute;
        background: $primary;
        z-index: 0;
        animation: related-videos-item-btn-hover-keyframe 0.3s linear 0s 1
          forwards;
        @include mq(sm) {
          animation: unset;
        }
      }
      &::after {
        content: '';
        left: 0;
        height: 60px;
        position: absolute;
        background: $black;
        z-index: 1;
        animation: related-videos-item-btn-hover-keyframe 0.2s linear 0.6s 1
          forwards;
        @include mq(sm) {
          animation: unset;
        }
      }
    }
  }
  &:hover {
    // @keyframes related-videos-item-title-keyframe {
    //   from {
    //     opacity: 0;
    //     height: 0px;
    //   }
    //   to {
    //     opacity: 1;
    //     height: 100%;
    //   }
    // }
    // .related-videos-item-title {
    //   opacity: 0;
    //   animation: related-videos-item-title-keyframe 0.4s linear 0.2s 1 forwards;
    //   @include mq(sm) {
    //     opacity: 1;
    //     animation: unset;
    //   }
    // }
    .related-videos-item-mask {
      border-left: 0.5px solid rgba(0, 0, 0, 0);
      border-right: 0.5px solid rgba(0, 0, 0, 0);
    }
    @keyframes related-videos-item-keyframe {
      from {
        opacity: 0;
        // height: 0px;
      }
      to {
        opacity: 1;
        // height: 80px;
      }
    }
    .related-videos-item-detail {
      animation: related-videos-item-keyframe 0.2s linear 0.2s 1 forwards;
      height: 80px;
      @include mq(sm) {
        animation: unset;
        height: 78px;
      }
    }
    @keyframes related-videos-item-btn-keyframe {
      from {
        opacity: 0;
        height: 0px;
      }
      to {
        opacity: 1;
        height: 60px;
      }
    }
    .related-videos-item-btn-animation {
      animation: related-videos-item-btn-keyframe 0.2s linear 0.2s 1 forwards;
      @include mq(sm) {
        animation: unset;
      }
    }
  }
}
</style>